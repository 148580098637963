import React from "react"
import { graphql, useStaticQuery ,Link } from "gatsby"
import SEO from "../components/seo";
import Img from "gatsby-image";

import Header from "../components/es/header";
import Footer from "../components/footer";

import "./../styles/mision.scss";

//Icon
import { BiArrowBack } from "react-icons/bi";


const Mision = () => {

  //Images
  const images = useStaticQuery(graphql`
        query {
            mision: file(relativePath: { eq: "mision.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            vision: file(relativePath: { eq: "consultoria_para_empresas_CIE_vision.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            valores: file(relativePath: { eq: "consultoria_para_empresas_CIE_valores.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            objetivo: file(relativePath: { eq: "objetivo.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
  `);

  let data = {
    "Mision": "Apoyar a dueños y directores de empresas a resolver sus necesidades y generarles soluciones inmediatas en conjunto, que les permitan eficientizar sus procesos administrativos y optimizar sus resultados operacionales.",
    "Vision": "Ser una solución a un requerimiento de integración de servicios, para que nuestros clientes no requieran de desarrollar departamentos especializados, pues dichos servicios se los ofreceremos nosotros, para obtener soluciones ágiles. Sin necesidad de tener empleados especializados, pues les podemos dar servicios por evento, proyecto o solución especial, y quedando siempre a disposición del cliente para servicios posteriores.",
    "Valores": <ul>
                <li>- Honestidad.</li>
                <li>- Profesionalismo.</li>
                <li>- Compromiso total con el cliente.</li>
                <li>- Integridad total en nuestro apoyo y ofrecimiento de soluciones.</li>
              </ul>,
    "Objetivo": "Ser una solución integral para los servicios especializados que requieran nuestros clientes, de modo que estén tranquilos de que siempre tendrán un equipo profesional de apoyo, dispuestos a generarle soluciones inmediatas a sus necesidades."
  }

  return (
    <section>
      <SEO
        title="Misión"
        description="En CIE, nuestra misión es "
      />
      <Header checked={"Misión, Visión y Valores"} />

      <article className="mision">

        <div className="title">
          <Link to="/" className="link" > <BiArrowBack size="18" className="icon" /> Inicio </Link>
          <h1>Misión, Visión, Valores y Objetivo.</h1>
        </div>

        <main>


          <div className="el el-l">
            <div className="text">
              <h2>Misión</h2>
              <p>{data.Mision}</p>
            </div>
            <Img fluid={images.mision.childImageSharp.fluid} className="img" alt="Negociacion entre dos personas"/>
          </div>

          <div className="el el-r">
            <div className="text">
              <h2>Visión</h2>
              <p>{data.Vision}</p>
            </div>
            <Img fluid={images.vision.childImageSharp.fluid} className="img" alt="Oficinas de consultoría" />
          </div>

          <div className="el el-l">
            <div className="text">
              <h2>Valores</h2>
              <p>{data.Valores}</p>
            </div>
            <Img fluid={images.valores.childImageSharp.fluid} className="img" alt="Oficinas de consultoría"/>
          </div>

          <div className="el el-r">
            <div className="text">
              <h2>Objetivo</h2>
              <p>{data.Objetivo}</p>
            </div>
            <Img fluid={images.objetivo.childImageSharp.fluid} className="img" alt="Vista desde edificio"/>
          </div>



        </main>

      </article>

      <Footer lang="es" />

    </section>
  );
}

export default Mision
